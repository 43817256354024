<template>
  <v-container>
    <div class="weather" v-for="(weather,i) in deodorizer_weather" :key="i">
      <div class="info_date">{{weather.search_date.substr(0,10)}}</div>

      <div class="weather_info" >
        <div style="text-align:center;">
        <div>
          <img
            :src="icon_sunny"
            v-show="weather.weather === 'clear' && weather.search_date >=  weather.sunrise && weather.search_date <=  weather.sunset"
          />
          <img
            :src="icon_sunny_night"
            v-show="weather.weather === 'clear' && (weather.search_date >  weather.sunset || weather.search_date <  weather.sunrise)"
          />
          <!-- 흐림 -->
          <img
            :src="icon_cloudy"
            v-show="weather.weather === 'cloudy'"
          />
          <!-- 약간 흐림 || 안개 -->
          <img
            :src="icon_partly_cloudy"
            v-show="(weather.weather === 'few clouds' || weather.weather === 'mist') && 
                    weather.search_date.substr(11,2) < '19' && weather.search_date.substr(11,2) > '06'"
          />
          <img
            :src="icon_partly_cloudy_night"
            v-show="(weather.weather === 'few clouds' || weather.weather === 'mist') && 
                    weather.search_date.substr(11,2) >= '19' && weather.search_date.substr(11,2) <= '06'"
          />
          <!-- 이슬비 -->
          <img :src="icon_drizzle" v-show="weather.weather === 'drizzle'" />
          <!-- 비 -->
          <img :src="icon_rain" v-show="weather.weather === 'rain'" />
          <!-- 뇌우 -->
          <img
            :src="icon_thunder"
            v-show="weather.weather === 'thunderstorm'"
          />
          <!-- 눈 -->
          <img :src="icon_snow" v-show="weather.weather === 'snow'" />

          <div class="temp_info">
              <v-icon color="#6B7B6E">mdi-thermometer</v-icon>
              <span class="ml-1">
                {{weather.temp}}
                <span class="unit">℃</span>
              </span>
              <span class="wind_value">
                <span style="color:#868686"> ( &nbsp;</span>
                <span style="color:#2B4C71">{{weather.today_lowest_temp}}℃ , &nbsp; </span>
                <span style="color:#743834">{{weather.today_highest_temp}}℃</span>
                <span style="color:#868686"> &nbsp; )</span>
              </span>
          </div>
        </div>
      </div>

      <div>
        <div class="wind_info ml-2 mr-2">
          <span class="weather_title mr-1"> 기상 </span>
          <div  class="value_info">
            <v-icon color="#6B7B6E">mdi-weather-windy</v-icon>
            <span class="ml-1">
              <span class="wind_speed"> {{weather.wind.speed}} </span>
              <span class="unit">m/s &nbsp; | </span>
              <span class="ml-1"> {{ getdirection(i) }} </span>
            </span>
          </div>

          <div class="value_info">
            <v-icon color="#617F8F">mdi-speedometer</v-icon>
            <span>
              {{weather.pressure}}
              <span class="unit">hPa &nbsp;</span>
            </span>
          </div>
        </div>

        <div class="wind_info_sensor ml-2 mr-2 mt-1" v-show="weather.sensor.pressure !== null ">
          <span class="weather_title mr-1"> 센서 </span>
              <div class="value_info">
                <v-icon color="#6B7B6E">mdi-weather-windy</v-icon>
                <span class="ml-1">
                  <span class="wind_speed"> {{weather.sensor.wind.speed}} </span>
                  <span class="unit">m/s &nbsp; | </span>
                  <span class="ml-1"> {{ getSensordirection(i) }} </span>
                </span>
              </div>

              <div  class="value_info">
                <v-icon color="#617F8F">mdi-speedometer</v-icon>
                <span>
                  {{weather.sensor.pressure}}
                  <span class="unit">hPa &nbsp;</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
  </v-container>
</template>

<script>
import Common from "@/utils/custom/common.js";

export default {
  name: "DeodorizerWeather",
  
  props: {
    deodorizer_weather: {
      type:Array,
    },
  },

  data() {
    return {
      icon_sunny: require("@/assets/monitoring/sunny.svg"),
      icon_sunny_night: require("@/assets/monitoring/sunny_night.svg"),
      icon_cloudy: require("@/assets/monitoring/cloudy.svg"),
      icon_partly_cloudy: require("@/assets/monitoring/partly_cloudy.svg"),
      icon_partly_cloudy_night: require("@/assets/monitoring/partly_cloudy_night.svg"),
      icon_rain: require("@/assets/monitoring/rain.svg"),
      icon_thunder: require("@/assets/monitoring/thunder.svg"),
      icon_snow: require("@/assets/monitoring/snow.svg"),
      icon_windy: require("@/assets/monitoring/windy.svg"),
      icon_drizzle: require("@/assets/monitoring/drizzle.svg"),
      icon_strong_wind: require("@/assets/monitoring/strong_wind.svg"),
      icon_refresh: require("@/assets/monitoring/icon_refresh.svg"),

      
      // pressure:this.deodorizer_weather.pressure,
      // search_date:this.deodorizer_weather.search_date,
      // icon_refresh: require("@/assets/monitoring/icon_refresh.svg"),
    };
  },
  
  methods: {
    refresh() {
      this.$emit("refreshList");
    },
    getdirection(i){
      return Common.getDirection(this.deodorizer_weather[i].wind.deg);
    },
    getSensordirection(i){
      return Common.getDirection(this.deodorizer_weather[i].sensor.wind.deg);
    }

  },
};
</script>
<style lang="scss" scoped>
.weather{
  border: 1px solid #6B7B6E;
  border-radius: 20px;
  padding:5px;
  width:80%;
  margin: 0 auto;
}
.weather_info{
  display:flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -1px;
  color: #3a4f3f;
  flex-wrap:wrap;
}
.weather_title{
  background-color:#9EB0A2;
  letter-spacing: -2px;
  color:white;
  padding:2px;
  width:65px;
  text-align:center;
  font-size:15px;
  border-radius:15px;
}
.temp_info{
  margin-top:-5px;
}

.value_info{
  width:180px;
}

.wind_info, .wind_info_sensor{
  display:flex;
  align-items: center;
  letter-spacing: -1px;
  color:#3a4f3f;
}

.wind_value{
  font-size:13px;
  text-align: center;
  letter-spacing: -1px;
}
.wind_speed{
  display:inline-block; 
  width:40px;
}
img{
  width: 35px;
  height: 35px;
}
.height{
  line-height:60px;
}
.unit{
  font-size:13px;
}
.info_date{
  text-align:center;
  font-size:13px;
  letter-spacing: -1px;
  color:#6B7B6E;
}

@media screen and (max-width: 768px){
  .wind_info, .weather_info, .wind_info_sensor{
    display:block;
    text-align:center;
  }
  .value_info{
    width:100%;
  }
  .weather_title{
    padding:5px;
  }
}
</style>
