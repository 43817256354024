<template>
  <v-container>
    <v-card outlined max-width="80%" class="mx-auto pa-1 mb-1 rounded-xl" >
      <v-layout wrap>
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 150px;"
          id="company" 
          ref="company" 
          label="경영체" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 150px;" 
          id="farm" 
          ref="farm" 
          label="농장/사업장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card>

    <div>
      <DeodorizerWeather
        :deodorizer_weather="deodorizer_weather"/>

      <div class="notice__info">
        ⓘ 숫자는 각 탈취라인의 우선순위 번호입니다. 
      </div>
    </div>

    <div class="deodorizer">
      <div v-for="(deodorizer,i) in deodorizers" :key="i" outlined
        class="elevation-2 control ma-2 pa-2 mr-2" :loading="loading" @click="clickDevicebox(i)"
        :class="{ green : deodorizer.remote_mode === 2 , white: deodorizer.remote_mode === 0 , red : deodorizer.remote_mode !== 2 && 0 }">
        
        <div class="setting_title">
          <div style="display:flex">
            <div>
              <v-icon class="mt-5 ml-1 mr-1" v-if="deodorizer.type.code === 'OT01' && deodorizer.weather === 'rain' && deodorizer.onoff === true"
              :class="{text_blue:deodorizer.remote_mode === 0, text_sky:deodorizer.remote_mode === 2  }">
                mdi-weather-pouring
              </v-icon>
              
              <v-icon class="mt-5 ml-1 mr-1" v-if="deodorizer.type.code === 'OT01' && deodorizer.weather === 'snow' && deodorizer.onoff === true"
              :class="{text_blue:deodorizer.remote_mode === 0, text_sky:deodorizer.remote_mode === 2  }">
                mdi-weather-snowy
              </v-icon>
            </div>

            <div>
              <div style="font-size:10px; letter-spacing: -1px;" :class="{ text_black: deodorizer.remote_mode === 0 && deodorizer.onoff !== true, text_white: deodorizer.remote_mode > 1 && deodorizer.onoff !== true, text_gold : deodorizer.remote_mode > 1 && deodorizer.onoff === true, text_green: deodorizer.remote_mode < 1 && deodorizer.onoff === true }">우선순위</div>

              <div class="info_priority ml-1"       
                @click="dialog(deodorizer)"> 
                {{ deodorizer.priority }}
              </div>
            </div>

            <v-dialog v-if="$getters.userInfo.grade < 'UT06'" 
              v-model="priority_dialog"
              :retain-focus="false"
              max-width="350"
              overlay-opacity="0.05"
              content-class="elevation-0"
              >
            
              <v-card class="pa-4" style="border-radius:10px;">
                <div class="priorityset_title">탈취장치 우선순위</div>

                <div class="priority_set">
                  <div class="priority_name">
                    <div class="priority_title mr-3"> 탈취라인 명</div>
                    <div> {{ prioritySet.device_name }}</div>
                  </div>

                  <div class="priority_name">
                    <div class="priority_title mr-3">우선순위</div>
                    <v-text-field
                    class="priority_set shrink"
                    type="number"
                    v-model="prioritySet.priority"
                    @close="priority_dialog = false"
                    dense
                    outlined/>
                  </div>
                </div>

                <v-card-actions class="justify-center">
                  <v-btn class="priority_btn" rounded outlined @click="savepriority()" >
                    적용
                  </v-btn>
                  <v-btn class="priority_btn" style="background:white; color:#3a4f3f" rounded outlined @click="priority_dialog = false">
                    닫기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


            <div class="deodorizer_title mb-2" 
                :class="{ text_black: deodorizer.remote_mode === 0 && deodorizer.onoff !== true, text_white: deodorizer.remote_mode > 1 && deodorizer.onoff !== true,
                text_gold : deodorizer.remote_mode > 1 && deodorizer.onoff === true, text_green: deodorizer.remote_mode < 1 && deodorizer.onoff === true }"> 
                <div> {{ deodorizer.device_name }} </div>
                <div class="info_room"> {{deodorizer.room.name}} </div>
            </div>
            <img :src="device_on" class="device_on mr-1" v-if="deodorizer.onoff === true && deodorizer.remote_mode < 2 && $getters.userInfo.grade < 'UT07'"/>
            <img :src="device_on" class="devicecontrol_on mr-1" v-if="deodorizer.onoff === true && deodorizer.remote_mode == 2 && $getters.userInfo.grade < 'UT07'"/>
          </div>
          <img :src="power" class="power_on mt-2 mr-1" @click="poweroff(i)" v-if="deodorizer.onoff && deodorizer.remote_mode < 2 && $getters.userInfo.grade < 'UT07'"/>
          <img :src="power" class="power_off mt-2 mr-1" @click="poweron(i)" v-if="!deodorizer.onoff && $getters.userInfo.grade < 'UT07'"/>
          <img :src="power" class="power_on_control mt-2 mr-1" @click="poweroff(i)" v-if="deodorizer.onoff && deodorizer.remote_mode === 2 && $getters.userInfo.grade < 'UT07'"/>
        </div>
        
        <div v-if="deodorizer.sensordata">
          <div class="setting_info">
            <div class="info_title"> 센서정보 </div>
            <div class="deodorizer_info mt-3 pl-3" v-for="(sensor,n) in deodorizer.sensordata" :key="n" >
              <div class="sensor_info">
                <div class="sensor_title">{{ sensor.name }}</div>
                <div>
                  <div>{{ sensor.value }} 
                    <span class="unit"> {{ dataUnit(i, n) }} &nbsp; </span>

                    <span class="unit" style="font-size:10px;"> ( </span>
                    <span class="sensor_value" style="color:#2B4C71"> {{sensor.lowest}} &nbsp; ,  &nbsp;</span>
                    <!-- <span class="unit" style="color:#2B4C71"> ppm , &nbsp;</span> -->
                    <span class="sensor_value" style="color:#743834"> {{sensor.highest}} </span>
                    <!-- <span class="unit" style="color:#743834"> ppm</span> -->
                    <span class="unit" style="font-size:10px;">  ) </span>
                  </div>       
                </div>
              </div>
            </div>
          </div>
        </div>

        <details class="setting_condition" >
          <summary style="font-weight:bold;"> 
            <div> 제어조건 </div>
            <span class="mdi mdi-24px mdi-package-down"
            v-show="deodorizer.timecontrol.on == true || deodorizer.sensorcontrol.on == true || deodorizer.weathercontrol.on == true">
            </span>
            <!-- <v-icon class="icon ml-3" color="#3a4f3f" 
              v-show="deodorizer.timecontrol.on == true || deodorizer.sensorcontrol.on == true || deodorizer.weathercontrol.on == true"> 
              mdi-package-down 
            </v-icon> -->
          </summary>

          <div class="control_back" v-if="deodorizer.timecontrol.on == true">
            <div class="control_title mt-2"> 
              <v-icon color="#9EB0A2" class="mr-1">mdi-clock</v-icon> 
              시간 제어
            </div>
              
            <div v-for="timecontrol in deodorizer.timecontrol.set" :key="timecontrol.set">
              <div class="control_set">
                <div class="control_stand"> 
                    {{ timecontrol.start }} - {{ timecontrol.finish }}
                </div>

                <div class="control_info">
                  <span>
                    <v-icon color="#9EB0A2"> mdi-play-circle-outline </v-icon>
                    작동 {{ timecontrol.run }}
                    <span class="unit mr-1">분 &nbsp;</span>
                  </span>

                  <span>
                    <v-icon color="#9EB0A2">mdi-stop-circle-outline</v-icon>
                    정지 {{ timecontrol.stop }}
                    <span class="unit">분 </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

            <div class="control_back" v-if="deodorizer.sensorcontrol.on == true">
              <div class="control_title mt-5"> 
                <v-icon color="#9EB0A2" class="mr-1">mdi-thermometer</v-icon> 
                센서 제어
              </div>

              <div v-for="(sensorcontrol,s) in deodorizer.sensorcontrol.set" :key="s">
                <div class="control_set">
                  <div class="sensorcontrol_stand">
                    {{sensorcontrol.sensor.name}} &nbsp;
                    <!-- <span> {{sensorcontrol.data_name.name}} </span> -->
                  </div>

                  <div class="sensorcontrol_info">
                    <span>
                      <v-icon color="#9EB0A2"> mdi-play-circle-outline </v-icon>
                      {{ sensorcontrol.stop }}
                    <span class="unit mr-1"> {{ dataUnit(i, s) }}</span>
                    <span class="control_title mr-1"> &lt;  </span>

                    <span> {{sensorcontrol.data_name.name}} &nbsp; </span>

                    <span class="control_title mr-1"> &gt; </span>
                    <span>
                      {{ sensorcontrol.run }}
                    <span class="unit"> {{ dataUnit(i, s) }} </span>
                    </span>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          
            <div class="control_back" v-if="deodorizer.weathercontrol.on == true">
              <div class="control_title mt-5"> 
                <v-icon class="mr-1" color="#9EB0A2">mdi-cloud</v-icon> 
                기상 제어
              </div>

              <div v-for="(weathercontrol,w) in deodorizer.weathercontrol.set" :key="w">
                <div class="control_set">
                  <div class="control_stand" v-if="weathercontrol.division.code == 'pressure'">
                    <span> {{weathercontrol.min}} &lt; </span>
                    {{weathercontrol.division.name}} 
                    <span> &gt; {{weathercontrol.max}}</span>
                  </div>

                  <div class="control_stand" v-if="weathercontrol.division.code == 'deg'">
                    <span class="deg_direction"> {{ degminDirection(i, w) }}, &NonBreakingSpace; {{ degmaxDirection(i, w) }}</span>
                  </div>

                  <div class="control_info">
                  <span>
                    <v-icon color="#9EB0A2"> mdi-play-circle-outline </v-icon>
                    작동 {{ weathercontrol.run }}
                    <span class="unit mr-1">분 &nbsp;</span>
                  </span>

                  <span>
                    <v-icon color="#9EB0A2">mdi-stop-circle-outline</v-icon>
                    정지 {{ weathercontrol.stop }}
                    <span class="unit">분 </span>
                  </span>
                </div>
                </div>
              </div>
            </div>
          </details>

          <div outlined class="set_btn" v-if="deodorizer.setbtn">
            <div v-if="deodorizer.remote_mode === 2">
              <v-row justify="center" align="center">
                <v-btn v-if="$getters.userInfo.grade < 'UT06'" rounded outlined class="stop_btn mr-3" :loading="loadingSet" @click="controlstop(i)">
                  자동제어중단
                </v-btn>
                <v-btn v-if="$getters.userInfo.grade < 'UT06'" rounded outlined class="modify_btn mr-3" @click="controlset(deodorizer.id)">
                  제어설정변경
                </v-btn>
              </v-row>
            </div>

            
            <div v-else-if="deodorizer.remote_mode < 2 && deodorizer.timecontrol.on === true || deodorizer.weathercontrol.on === true || deodorizer.sensorcontrol.on === true">
              <v-row justify="center" align="center">
                <v-btn v-if="$getters.userInfo.grade < 'UT06'" rounded outlined class="start_btn mr-3" :loading="loadingSet" @click="controlstart(i)">
                  자동제어시작
                </v-btn>
                <v-btn v-if="$getters.userInfo.grade < 'UT06'" rounded outlined class="setting_btn mr-3" @click="controlset(deodorizer.id)">
                  자동제어설정
                </v-btn>
              </v-row>
            </div>

            <div v-else-if="deodorizer.remote_mode < 2 && deodorizer.timecontrol.on === false && deodorizer.weathercontrol.on === false && deodorizer.sensorcontrol.on === false">
              <v-row justify="center" align="center">
                <v-btn v-if="$getters.userInfo.grade < 'UT06'" rounded outlined class="setting_btn mr-3" @click="controlset(deodorizer.id)">
                  자동제어설정
                </v-btn>
              </v-row>
            </div>

            <!-- <div v-else-if="deodorizer.remote_mode !== 0 && 2 ">
              <v-row justify="center" align="center">
                <v-btn rounded outlined class="stop_btn" @click="controlset(deodorizer.id)">
                  제어확인
                </v-btn>
              </v-row>
            </div> -->
          </div>

          </div>
      </div>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
import DeodorizerWeather from "./child/DeodorizerWeather.vue";
import common from '@/utils/custom/common';
// import Common from '@/utils/custom/common';

export default {
  name: "DeodorizerMonitoring",

  components:{
    DeodorizerWeather,
  },
  
  async created(){
    Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    await this.comboCompanies();
    await this.comboFarms();

    let cookie_info = VueCookies.get("devicesummary"); // 이전 조회정보를 참조

    if (!this.isNull(this.$route.params)) {
      // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
    } else if (cookie_info) {
        this.company = cookie_info.company || {};
        this.farm = cookie_info.farm || {};
    } else {  // 이전 조회정보가 없을 경우 첫번째 농장/사업장 디폴트로 참조
        this.farm = this.farmItems[0];
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
    }

    this.refreshList();
  },

  data(){
    return{
      device_on: require("@/assets/monitoring/device_on.svg"),
      power: require("@/assets/monitoring/power.svg"),

      priority_dialog: false,
      prioritySet : {device_name :"", id:"", priority :""},

      company : {},
      companyItems : [],
      farm: {},
      farmItems: [],

      deodorizers: [],

      deodorizer_weather: [],
      direction:"",

      loading: false,
      loadingSet: false,

      // setbtn: false,
    }
  },
  methods:{
    dialog(item){
      event.stopPropagation();    // 상위박스 클릭이벤트 중복방지

      this.$store.commit("resMessage","");

      this.prioritySet = {  device_name : item.device_name, id : item.id,
                            priority : item.priority };
      // console.log(this.prioritySet)
      this.priority_dialog = true;
    },

    refresh(){
      this.priority_dialog = false;
      this.refreshList();
    },
    savepriority(){
      Apis.updateDeodorizer({
        id: this.prioritySet.id,
        priority: Number(this.prioritySet.priority)
      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);

        this.priority_dialog = false;
        this.refreshList();
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("updateDeodorizer 호출 오류",err);

        // console.log(this.prioritySet.id , this.prioritySet.priority)
      });
    },

    clickDevicebox(i){
      this.deodorizers[i].setbtn =! this.deodorizers[i].setbtn;
    },
    dataUnit(s, n){
      return common.dataUnit(this.deodorizers[s].sensorcontrol.set[n].data_name.code)
    },
    degminDirection(a,b){
      return common.getDirection(this.deodorizers[a].weathercontrol.set[b].min)
    },
    degmaxDirection(c,d){
      return common.getDirection(this.deodorizers[c].weathercontrol.set[d].max)
    },

    refreshList() {
      this.$store.commit("resMessage","");
      this.refreshWeatherInfo();

      if (!this.farm || !this.farm.code ) {
        alert("농장/사업장을 선택하세요.");
        this.$refs.farm.focus();
        return;
      }
      this.loading = true;

      Apis.listMonitorDeodorizers({
        farm_cd:  this.farm && this.farm.code,
      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);

        res.data.forEach((deodorizer) => {
          deodorizer.setbtn=false;
        })

        this.deodorizers = res.data;
        
        // console.log(res.data[0].priority);

        // console.log("sensorcontrol_set", res.data[0].weathercontrol.set[0])
        // console.log(res.data);
        // console.log("sensorcontrol_set", res.data[0].sensorcontrol.set[0].data_name.code)

        this.loading = false;
        let cookie_info = {company: this.company, farm: this.farm};
        VueCookies.set("devicesummary" ,cookie_info,"30d");

        // console.log(res.data);
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listMonitorDeodorizers 호출 오류",err);
        alert("listMonitorDeodorizers 호출 오류" + err);
      });
    },
    refreshWeatherInfo() {
      Apis.weatherForDeodorizer({
        farm_cd:  this.farm && this.farm.code,
      } ,(res) => {
        // NOTE: 해당 농장/사업장정보에 등록된 위치정보 기반 날씨 정보
        // console.log("weather=",res.data)

        this.deodorizer_weather = res.data;

        // console.log("deg", res.data[0].wind)
      });
    },

    controlset(id){
        this.$router.push({
        name: "DeodorizerSet",
        params: {
          id: id
        }
      });
    },

    controlstart(i){
      event.stopPropagation();    // 상위박스 클릭이벤트 중복방지
      if (!confirm("제어를 시작하시겠습니까?"))  return;

      this.$store.commit("resMessage","");
      this.loadingSet = true;

      Apis.setStatusOfDeodorizer({
        deodorizer_id:  this.deodorizers[i].id,
        on: this.deodorizers[i].onoff && this.deodorizers[i].onoff || false,
        remote_mode: 2,
      } ,(res) => {
        this.$store.commit("resMessage",res.message);
        this.loadingSet = false;
        if (res.result) this.deodorizers[i].remote_mode = 2;    // 정상처리
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        this.loadingSet = false;
        console.log("setStatusOfDeodorizer 호출 오류",err);
        alert("setStatusOfDeodorizer 호출 오류" + err);
      });
    },
    controlstop(i){
      event.stopPropagation();
      if (!confirm("제어를 중지하시겠습니까?"))  return;

      this.$store.commit("resMessage","");
      this.loadingSet = true;

      Apis.setStatusOfDeodorizer({
        deodorizer_id:  this.deodorizers[i].id,
        on: this.deodorizers[i].onoff && this.deodorizers[i].onoff || false,
        remote_mode: 0,
      } ,(res) => {
        this.$store.commit("resMessage",res.message);
        this.loadingSet = false;
        if (res.result) this.deodorizers[i].remote_mode = 0;    // 정상처리
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        this.loadingSet = false;
        console.log("setStatusOfDeodorizer 호출 오류",err);
        alert("setStatusOfDeodorizer 호출 오류" + err);
      });
    },
    poweron(i){
      event.stopPropagation();
      if (!confirm("해당 디바이스를 작동하시겠습니까?"))  return;
      
      this.$store.commit("resMessage","");
      this.loadingSet = true;

      Apis.setStatusOfDeodorizer({
        deodorizer_id:  this.deodorizers[i].id,
        on: true,
        remote_mode: this.deodorizers[i].remote_mode && this.deodorizers[i].remote_mode || 0 ,
      } ,(res) => {
        this.$store.commit("resMessage",res.message);
        this.loadingSet = false;
        if (res.result) this.deodorizers[i].onoff = true;    // 정상처리
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        this.loadingSet = false;
        console.log("setStatusOfDeodorizer 호출 오류",err);
        alert("setStatusOfDeodorizer 호출 오류" + err);
      });
    },

    poweroff(i){
      event.stopPropagation();
      if (!confirm("해당 디바이스를 정지하시겠습니까?"))  return;

      this.$store.commit("resMessage","");
      this.loadingSet = true;

      Apis.setStatusOfDeodorizer({
        deodorizer_id:  this.deodorizers[i].id,
        on: false,
        remote_mode: this.deodorizers[i].remote_mode && this.deodorizers[i].remote_mode || 0,
      } ,(res) => {
        this.$store.commit("resMessage",res.message);
        this.loadingSet = false;
        if (res.result) this.deodorizers[i].onoff = false;    // 정상처리
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        this.loadingSet = false;
        console.log("setStatusOfDeodorizer 호출 오류",err);
        alert("setStatusOfDeodorizer 호출 오류" + err);
      });
    },

    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },

    async changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};

      if (this.company && this.company.code) {   
        await this.comboFarms();
        if (this.company) this.farm = this.farmItems[0];
      }
    },        

    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
        this.building = {};
      }
      this.buildingItems = [];
    },

  } // methods end

}
</script>

<style lang="scss" scoped>
.power_off{
  width:30px;
  height:30px;
  cursor:pointer;
  filter: invert(60%) sepia(0%) saturate(1052%) hue-rotate(166deg) brightness(89%) contrast(89%);
}
.power_on{
  width:30px;
  height:30px;
  cursor:pointer;
  filter: invert(25%) sepia(31%) saturate(4878%) hue-rotate(144deg) brightness(93%) contrast(101%);
}
.power_on_control{
  width:30px;
  height:30px;
  cursor:pointer;
  filter: invert(100%) sepia(23%) saturate(6254%) hue-rotate(310deg) brightness(108%) contrast(105%);
}
.notice__info{
  text-align:right;
  font-size:15px;
  letter-spacing: -1px;
  margin-right:10px;
  color:#868686;

}
.info_priority{
  width:25px;
  height:30px;
  line-height:30px;
  cursor:pointer;
  text-align: center;
  color:white;
  background-color: #9EB0A2;
  border-radius:10px;
}
.priorityset_title{
  font-size:20px;
  margin-bottom:15px;
  font-weight:bold;
  letter-spacing: -1px;
}
.priority_name{
  display:flex;
  padding-left:5px;
  letter-spacing: -1px;
}
.priority_title{
  font-weight:bold;
  width:100px;
}
.priority_set{
  margin:5px;
  height:100px;
  line-height:45px;
}
.priority_btn{
  background-color: #3a4f3f;
  width:120px;
  height:45px !important;
  font-weight:bold;
  font-size:17px;
  color:white;
  letter-spacing: -0.5px;
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.situation{
  display: flex;
}
.situation_info{
  width:65px;
  padding:3px;
  font-size:13px;
  font-weight:bold;
  text-align: center;
  border-radius: 5px;
}
.deodorizer_info{
  display:flex;
}
.deodorizer{
  display:flex;
  flex-wrap: wrap;
  // width: calc(100% - 200px);
  justify-content: center;
}

.control{
  cursor:pointer;
  border:1px solid #3a4f3f !important;
  width:370px;
  border-radius: 10px;
  height:auto;
  margin-bottom: 5px;
  margin-left: 5px;
  padding-top: 2px;
}

.setting_title{
  display:flex;
  justify-content: space-between;
  font-weight: bold;
}
.device_on{
  width:20px;
  height:20px;
  margin-top:10px;
  margin-left:5px;
  filter: invert(25%) sepia(43%) saturate(470%) hue-rotate(129deg) brightness(93%) contrast(88%);
}
.devicecontrol_on{
  width:20px;
  height:20px;
  margin-top:10px;
  margin-left:5px;
  filter: invert(89%) sepia(30%) saturate(732%) hue-rotate(335deg) brightness(103%) contrast(94%);
}
.deodorizer_title{
  font-size:19px;
  padding-top:5px;
  padding-left:7px;
  padding-bottom: 5px;
  text-align:left;
  letter-spacing: -1px;
}
.info_room{
  font-size:15px;
  font-weight: lighter;
  color:#868686;
}
.location_info{
  display:flex;
  height:25px;
  line-height:25px;
  padding-left: 5px;
}

.setting_info, .setting_condition{
  margin-top:5px;
  background-color: #F6F6F6;
  border-radius: 5px;
  text-align: left;
  padding: 10px;
}

.info_title{
  width:80px;
  font-weight:bold;
}
.sensor_info, .timecontrol_info{
  display:flex;
  margin-top:-7px;
}
.sensor_title{
  width:160px;
  font-size:15px;
  // font-weight:bold;
  display:flex;
  letter-spacing: -1px;
}
.unit{
  font-size:12px;
  letter-spacing: -1px;
}
.sensor_value{
  font-size:13px;
  letter-spacing: -1.25px;
}

.control_set{
  display:flex;
  margin-left:5px;
  justify-content: space-between;
  margin-top:3px;
}
.control_title{
  letter-spacing: -1px;
  font-weight: bold;
}
.control_stand{
  letter-spacing: -1px;
}

.sensorcontrol_stand, .deg_direction{
  letter-spacing:-2px;
}

.control_info{
  margin-top:-5px;
  letter-spacing: -1px;
  width:200px;
}
.sensorcontrol_info{
  margin-top:-5px;
  letter-spacing: -1.5px;
  width:225px;
}
.set_btn{
  padding:30px 0 25px 0;
}
.start_btn{
  background-color: #3a4f3f;
  width:120px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  color:white;
  letter-spacing: -0.5px;
}
.modify_btn{
  background-color: white;
  width:120px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  color:#3a4f3f;
  letter-spacing: -0.5px;
}

.setting_btn{
  background-color: #D2E4D6;
  color:#3a4f3f;
  width:120px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}

.stop_btn{
  background-color: #88191a;
  width:120px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  color:white;
  letter-spacing: -0.5px;
  border-color:transparent;
}
details {
  user-select: none;
}
details>summary span.mdi {
  width: 24px;
  height: 24px;
  line-height: 24px;
  transition: all 0.3s;
  margin-left: auto;
  color:#3a4f3f;
}

details[open] summary span.mdi {
  transform: rotate(180deg);
}

summary {
  display: flex;
  cursor: pointer;
}

summary::-webkit-details-marker {
  display: none;
}

.green{
  background-color: #3a4f3f !important;
}

.red{
  background-color:#88191a !important;
}

.gray {
  background-color: #868686 !important;
}

.text_green{
  color:#005B46;
}
.text_gold{
  color: #F7DA81;
}

.text_black{
  color:black;
}
.text_white{
  color:white;
}
.text_blue{
  color:#617F8F;
}
.text_sky{
  color:#93E2E4;
}
.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
}


</style>